@media print {
	#wrapper #content #main-content.interna {
		#column-0, #column-2, #column-1, #column-3, #column-4, #column-5 {
			width:100%!important;
		}
	}
	
	.has-control-menu #wrapper, #wrapper {
	    margin-top: 0!important;
	}
	
	#wrapper #content #main-content.colonna-70-30, #wrapper #content #main-content.colonna-50-50, #wrapper #content #main-content.colonna-30-70 {
		#column-3 {
			width:100%!important;
		}
	}
	
	#wrapper #content #main-content.colonna-25-75 {
		#column-2 {
			width:100%!important;
		}
	}
	
	.toggler-content-collapsed {
		display:block!important;
		padding: 0 0 30px!important;
	}
	
	a[href]:after {      
		content: ""!important; 
	}
	
	#course .tutor, #course .dl-horizontal dd:nth-of-type(2n -1), #course .dl-horizontal dt:nth-of-type(2n -1), #course-tab ul.nav-tabs li a, #course-tab .tab-content .toggler-general .toggler-content, #course #infoCourse #planStudy .nav-tabs li a {
	    background-color: #dddddd!important;
	}
	
	#course .col-md-9 {
	    width: 75%!important;
	    float:left!important;
	}
	
	#course .col-md-3 {
	    width: 25%!important;
	    float:left!important; 
	}
	
	#course-tab .tab-content .area h4, #course #infoCourse h3 {
		background-color: #f0cb00!important;
	}
	
	.control-menu {
		display:block!important;
	}
	
	.dl-horizontal dt {
		float:left!important;
		width:220px!important;
	}
	
	#course-tab ul.nav-tabs li.active>a, #course-tab ul.nav-tabs li.active>a:hover, #course-tab ul.nav-tabs li.active>a:focus, #course #infoCourse #planStudy .nav-tabs li.tab-selected a {
	    background-color: #fff!important;
	}
	
	#course #infoCourse #planStudy .nav-tabs li a {padding:9px 10px!important;}
} 